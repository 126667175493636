<template>
    <div>
      <Section1 :keyWord="'Préstamos con asnef en el acto'" />
      <div class="texto-landing">
        <!-- Escribe aquí gaston, gracias :) -->
        <h2 class="title-h1 text-center pb-4"><strong>Préstamos con ASNEF en el acto: una solución financiera rápida y accesible</strong></h2>
        <p>En el ámbito financiero, nos encontramos en ocasiones con situaciones imprevistas que demandan de una respuesta inmediata. Enfrentarse a ellas si estamos dentro de la lista de ASNEF se presenta como uno de los desafíos más habituales, complicando así la posibilidad de acceder a préstamos. No obstante, en esta ocasión, examinaremos detalladamente 
            la alternativa de los préstamos con <strong>ASNEF en el acto</strong>, una opción que puede marcar la diferencia en momentos de gran importancia.</p>
        <h3 class="naranja"><strong>¿Qué son los préstamos con ASNEF en el acto?</strong></h3>
        <p class="">Estar en la lista de ASNEF puede hacer que <strong>obtener un préstamo parezca una tarea desafiante. No obstante, los</strong> préstamos con ASNEF en el acto representan una solución rápida ante esta situación. Su diseño está orientado a proporcionar respuestas financieras inmediatas, ofreciendo a las personas la posibilidad de superar obstáculos económicos sin largas esperas.</p>
  
        <h3 class="naranja"><strong>Ventajas de los préstamos con ASNEF en el acto</strong></h3>
        <p class=""><strong>Rapidez en la aprobación:</strong> una de las principales ventajas de estos préstamos es la rapidez con la que se aprueban. Las instituciones financieras especializadas en este tipo de servicios comprenden la urgencia de la situación y agilizan el proceso de aprobación.</p>
        <p class=""><strong>Accesibilidad:</strong> a pesar de la presencia en ASNEF, estas opciones de préstamo están diseñadas para ser accesibles. Se centran en otros aspectos de tu situación financiera y pueden proporcionar el impulso necesario para salir adelante.</p>
  
        <h3 class="naranja"><strong>Cómo obtener préstamos con ASNEF en el acto</strong></h3>
        <p class="">El proceso para solicitar estos préstamos es sencillo y conveniente:</p>
        <h3 class="naranja"><strong>Investigación y comparación</strong></h3>
        <p>Antes de elegir una entidad financiera, es esencial investigar y comparar las opciones disponibles. Busca aquellas que se especializan en préstamos con ASNEF y compara tasas de interés y condiciones.</p>
        <h3 class="naranja"><strong>Solicitud en línea</strong></h3>
        <p>La mayoría de las instituciones que ofrecen préstamos con ASNEF permiten la solicitud en línea. Este proceso facilita la presentación de la documentación necesaria y agiliza la aprobación.</p>
        <h3 class="naranja"><strong>Respuesta rápida</strong></h3>
        <p>La característica distintiva de estos préstamos es la respuesta rápida. En cuestión de horas, podrías recibir la aprobación y tener el dinero en tu cuenta.</p>
        <h3 class="naranja"><strong>Requisitos básicos</strong></h3>
        <p>Aunque estás en ASNEF, las entidades aún pueden requerir información sobre ingresos y otras garantías. Asegúrate de tener todos los documentos necesarios a mano.</p>
        <h3 class="naranja"><strong>Consejos para optimizar tus préstamos con ASNEF en el acto</strong></h3>
        <ol>
            <li><strong>Mantén una comunicación transparente:</strong> es crucial ser transparente con la entidad financiera. Explica claramente la razón detrás de tu presencia en ASNEF y demuestra cómo planeas cumplir con los pagos.</li>
            <li><strong>Encuentra la mejor opción para ti:</strong> en el competitivo mundo de los préstamos con ASNEF, es esencial comparar las opciones disponibles y encontrar la que mejor se adapte a tus necesidades y circunstancias.</li>
            <li><strong>La importancia de una respuesta rápida:</strong> en situaciones financieras urgentes, la velocidad de respuesta es crucial. Descubre cómo obtener la aprobación en el acto y asegúrate de aprovechar al máximo esta opción.</li>
        </ol>
        <p><strong> Los préstamos con ASNEF en el acto</strong> son una herramienta valiosa para aquellos que enfrentan desafíos financieros que necesiten una respuesta inmediata. Con la información adecuada y una solicitud bien preparada, puedes superar los obstáculos económicos de forma rápida y efectiva. Recuerda, la transparencia y la investigación son tus aliados en este camino hacia la estabilidad financiera. Si requieres de asesoramiento, no dudes en contactar con Ibancar, nos avala una experiencia sólida ayudando a personas, en momentos complicados. ¡No dejes que ASNEF sea un obstáculo insuperable y busca las soluciones que te ayudarán a avanzar!</p>
  
      </div>
      <Section2 />
      <CreditoCocheS4 /> <!-- acordeón de preguntas -->
      <div class="relleno"></div>
      
      <div class="cards-prestamo">
        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
          <div class="padding">
              <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
          </div>
        </div>
  
        <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
            <div class="card m-4"  >
              <a href="../prestamo-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../dinero-rapido-con-tu-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
                </div>
              </a>
            </div>
        </div>
        
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-madrid" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-sevilla" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
                </div>
              </a>
            </div>
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
                </div>
              </a>
            </div>
        </div>
  
        
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4">
              <a href="../credito-por-tu-coche-en-barcelona" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito por tu coche en Barcelona</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Dinero por tu coche, préstamo por tu coche, empeñar tu coche, hipoteca tu coche.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../credito-aval-coche-las-palmas" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con Asnef y nómina Rápidos</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
                </div>
              </a>
            </div>
        </div> 
  
  <!-- fila 4  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
            </div>
            <div class="card m-4"  >
              <a href="../prestamos-por-tu-coche-alicante" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
                </div>
              </a>
            </div>
        </div>
  
  <!-- fila 5  -->
        <div class="d-flex flex-row justify-content-center flex-wrap">
            <div class="card m-4"  >
              <a href="../prestamos-aval-coche-valencia" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
                </div>
              </a>
            </div>
            
            <div class="card m-4"  >
              <a href="../credito-coche" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Crédito con aval</h5>
                    <span class="caret-sign">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
                </div>
              </a>
            </div>
        </div> 
  
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="flex-column p-5">
                <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
            </div>
        </div>
  
      </div>
      <div class="relleno"></div>
      <!-- No te pases de aquí gaston, gracias 2.0 :) -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Section1 from '@/components/Home/Section1.vue'
  import { useMeta } from 'vue-meta'
  
  import Section2 from '@/components/Home/Section2.vue'
  import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'
  
  // @ is an alias to /src
  export default {
    name: 'Home',
    components: {
      Section1,
      Section2,
      CreditoCocheS4
    },
    setup() {
  
      useMeta({
        title: 'Préstamos con ASNEF en el acto',
        meta:[
          {name:'robots', content:'index'},
          {name:'description', content:'Consigue préstamos con ASNEF de manera rápida y sencilla'},
          {name:'keywords', content:'préstamos con asnef en el acto'}
        ]
      })
      }
  }
  </script>
  
  <style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .naranja {
    color: $orange;
    font-weight: bold;
  }
  .texto-landing {
    padding: 3em 15em;
    line-height: 2em;
  }
  .card {
    width: 22rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 10rem;
  }
  .inline {
    display: inline;
  }
  .caret-sign, .caret-sign-city {
    margin-left: 25px;
    vertical-align: top;
    float: right;
  }
  .caret-sign-city {
    position: relative;
    top: -27px;
  }
  a.no {
    text-decoration: none;
    color: #333;
  }
  a.no:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    bottom: 1px;
    height: 10rem;
  }
  .relleno {
    height: 50px;
  }
  
  p.destacar {
      margin: 24px 0px;
      font-size: 1.2em;
      background-color: #FDF5E6;
      padding: 30px;
  }
  
  @import 'bootstrap/scss/_functions.scss';
  @import 'bootstrap/scss/_variables.scss';
  @import 'bootstrap/scss/_mixins.scss';
  
  @include media-breakpoint-between(xs,sm) {
    .texto-landing {
      padding: 1.5em 2em;
    }
    .card {
      height: 100%;
    }
    a.no:hover {
      height: 100%;
    }
    .title-h1 {
      font-size: 32px;
    }
    .naranja {
      font-size: 24px;
    }
  }
  
  @include media-breakpoint-between(md,lg) {
    .texto-landing {
      padding: 1.5em 5em;
    }
  }
  
  @include media-breakpoint-between(xl,xxl) {
  
  }
  </style>